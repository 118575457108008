<template>
  <div v-click-outside="outside" class="relative inline-block text-left">
    <div>
      <button
        type="button"
        class="inline-flex justify-center items-center w-full rounded border border-gray-300 shadow-sm px-2 py-1 bg-white text-xs font-small text-gray-700 hover:bg-gray-50 focus:outline-none"
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
        @click.prevent="$emit('input', !value)"
      >
        {{ label }}
        <!-- Heroicon name: solid/chevron-down -->
        <svg
          class="-mr-1 ml-2 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>

    <div
      v-if="value"
      class="origin-top-right absolute right-0 z-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-10 divide-y divide-gray-100 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="-1"
    >
      <div
        class="py-1"
        role="none"
        v-for="(action, index) in actions"
        :key="`action-${index}`"
      >
        <a
          href="#"
          class="group flex items-center px-3 py-1 text-xs space-x-2`"
          :class="
            action.disabled
              ? 'text-gray-400 cursor-not-allowed'
              : 'text-gray-700'
          "
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
          @click.prevent="!action.disabled && notify(action.event)"
        >
          <span v-html="media(action.icon)"></span>
          <span>{{ action.label }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  name: 'CGDropdown',
  props: {
    value: {
      default: false,
    },
    label: {
      default: 'Aktion',
    },
    actions: {
      required: true,
    },
  },
  directives: {
    ClickOutside,
  },
  methods: {
    outside() {
      this.$emit('input', false)
    },
    notify(event) {
      this.$emit('notify', event)
      this.outside()
    },
  },
}
</script>

<style></style>
