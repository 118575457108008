<template>
  <div>
    <cgdata-table :columns="columns" endpoint="personal/?no_pagination=true">
      <template slot-scope="{ row }">
        <td
          class="whitespace-nowrap py-2 px-3 text-sm font-medium text-gray-900"
        >
          <router-link :to="`/personal/${row.id}`"
            >{{ row.first_name }} {{ row.last_name }}</router-link
          >
        </td>
        <td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
          {{ row.email }}
        </td>
        <td
          class="whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
        >
          <CGDropdown
            v-model="row.show"
            :actions="actions(row)"
            @notify="event => onAction({ event, row })"
          />
        </td>
      </template>
    </cgdata-table>
  </div>
</template>

<script>
// import axios from '@/utils/axios'
import { inviteOne, destroyOne } from '@/services/personal'
import CGDropdown from '@/components/Commons/CGDropdown'

export default {
  name: 'invite-personal',
  props: [],
  components: { CGDropdown },
  data() {
    return {
      columns: [
        {
          label: 'Name',
          width: '25',
        },
        {
          label: 'E-Mail',
          width: '25',
        },
      ],
      rows: [],
      breadcrumbs: {
        title: [
          { crumb: 'Allgemeine Einstellungen', link: '/settings' },
          { crumb: 'Benutzer verwalten', link: null },
        ],
      },
    }
  },
  mounted() {},
  methods: {
    actions(row) {
      return [
        {
          label: 'Benutzer aktivieren',
          icon: 'invite',
          event: 'invite',
          disabled: row.user,
        },
        {
          label: 'Benutzer löschen',
          icon: 'delete',
          event: 'destroy',
          disabled: false,
        },
      ]
    },
    destroy({ user }) {
      user && destroyOne({ id: user })
    },
    invite({ email }) {
      this.setModal({
        show: true,
        buttons: [
          {
            label: 'Ja',
            style:
              'border-transparent bg-blue-600 text-white hover:bg-blue-700 sm:ml-3',
            event: 'invite',
            confirm: true,
            callback: () => email && inviteOne({ email }),
          },
          {
            label: 'Nein',
            style:
              'border-gray-300 mt-3 bg-white text-gray-700 hover:bg-gray-50 sm:mt-0',
            event: 'invite',
            confirm: false,
            callback: () => {},
          },
        ],
        title: 'Benutzer verwalten',
        message: `Diesem Benutzer kann eine benobix Benutzerlizenz zugewiesen werden, wenn keine Lizenz vorhanden ist. (2.50 CHF pro Benutzer/Monat)`,
        notice: 'Benutzer hat Zugang',
      })
    },
    onAction({ event, row }) {
      this[event](row)
    },
  },
}
</script>
